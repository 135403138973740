import React from "react";
import Background from "./Background";

export default function LandingBlock() {
  return (
    <div className="landing-block">
      <Background />
      <div className="landing-block-container">
        <h1 className="logo" color="white">
          CosmicDot•
        </h1>
        <p>
          Developing the future <br /> one block at the time
        </p>
      </div>
    </div>
  );
}

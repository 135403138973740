// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import Cube from "../../assets/cube.svg";
import { animated, useTrail, useSpring, a } from "@react-spring/web";
import { useIntersectionObserver } from "../../utils/animation";
import { Trail } from "../Trail";

export default function WhatWeDo() {
  const triggerRef = useRef();

  const dataRef = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: false,
  });

  const headerStyle = useSpring({
    config: { duration: 500 },
    from: { opacity: 0, marginBottom: "100px" },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      marginBottom: dataRef?.isIntersecting ? "0px" : "100px",
    },
  });

  return (
    <animated.div className="what-container information-block">
      <div className="what-image">
        <img className="cube-image" src={Cube} alt="cube" width={200} />
      </div>
      <animated.div className="title-question-mark" style={headerStyle}>
        <div ref={triggerRef} />
        {/* <Trail open={dataRef?.isIntersecting}> */}
        <div>
          <h1>What we do?</h1>
          <p>
            We are passionate about building innovative software solutions that
            empower businesses and individuals to thrive in today's fast-paced
            digital landscape. With a particular focus on supporting blockchain
            providers, we work tirelessly to help them develop and strengthen
            their ecosystems.
          </p>
        </div>
        <div ref={triggerRef} />
        {/* <Trail open={dataRef?.isIntersecting}> */}
        <div>
          <p>
            Whether you are a startup looking to launch your blockchain-based
            business, or an established provider seeking to expand your
            offerings, we have the skills and expertise you need to succeed.
            With a deep understanding of the unique challenges and opportunities
            that come with blockchain technology, we are confident in our
            ability to help you achieve your goals and reach your full
            potential.
          </p>
        </div>
      </animated.div>
    </animated.div>
  );
}

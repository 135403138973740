import React from "react";
import "./style.css";
import Art1 from "../../assets/art1.jpg";
import Art2 from "../../assets/art2.jpg";

export default function Contact() {
  return (
    <div className="contacts-container information-block">
      <div className="art-img">
        <img id="art-2" src={Art1} alt="art" width={250}/>
        <img id="art-1" src={Art2} alt="art" width={250}/>
      </div>
      <div className="contacts-content">
        <h1>
          Start building <br /> the future
        </h1>
        <a href="javascript:window.location.href = 'mailto:' + ['hello','cosmicdot.com'].join('@')">
          <button>Contact us</button>
        </a>
        <p>We are happy to connect :)</p>
      </div>
    </div>
  );
}

// @ts-nocheck
import React, { useRef } from "react";
import "./style.css";
import Ball from "../../assets/ball.svg";
import { useIntersectionObserver } from "../../utils/animation";
import { useSpring, animated } from "@react-spring/web";
import { Trail } from "../Trail";

export default function WhoWeAre() {
  const triggerRef = useRef();

  const dataRef = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: false,
  });

  const headerStyle = useSpring({
    config: { duration: 500 },
    from: { opacity: 0, marginBottom: "100px" },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      marginBottom: dataRef?.isIntersecting ? "0px" : "100px",
    },
  });
  return (
    <div className="who-container information-block">
      <animated.div className="title-question-mark" style={headerStyle}>
        {/* <Trail open={dataRef?.isIntersecting}> */}
        <div>
          <h1>Who we are?</h1>
          <p>
            Our team brings a wealth of expertise to every project we undertake,
            leveraging cutting-edge technologies and industry best practices to
            deliver solutions that are both powerful and user-friendly. From
            creating custom software applications to developing scalable
            platforms, we are committed to delivering exceptional value to our
            clients.
          </p>
        </div>
        <div ref={triggerRef} />
        {/* <Trail open={dataRef?.isIntersecting}> */}
        <div>
          <p>
            Over the years, we have had the privilege of working on a wide
            variety of exciting and innovative projects. From blockchain
            explorers to non-custodial wallets, mobile apps to marketplaces, we
            have helped our clients build solutions that are at the forefront of
            technology and user experience.
          </p>
        </div>
      </animated.div>
      <div className="who-image">
        <img className="ball-image" src={Ball} alt="cube" width={200} />
      </div>
    </div>
  );
}

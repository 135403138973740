import React from "react";
import "./style.css";
import GMM from "../../assets/gmm.png";

export default function Partners() {
  return (
    <div className="partners-box">
      <h2>Partners & Services</h2>
      <div className="partners-content">
        <div className="vertical-content">
          <div className="partners">
            <div className="partner">
              <img src={GMM} alt="GMM" />
            </div>
            <div className="partner other-partners">... and others </div>
          </div>
          <div className="anon-partners">
            some partners like to stay anon ;)
          </div>
        </div>
        <div className="services-container">
          <div className="services">
            <ul>
              <li>Web3 development</li>
              <li>App development</li>
              <li>Infrastructure</li>
            </ul>
          </div>
          <div className="services">
            <ul>
              <li>Node management</li>
              <li>Blockchain Ecosystems</li>
              <li>Non custodial solutions</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

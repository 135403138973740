import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import LandingBlock from "./components/Landing/LandingBlock";
import WhatWeDo from "./components/What/WhatWeDo";
import "./App.css";
import WhoWeAre from "./components/Who/WhoWeAre";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Partners from "./components/Partners/Partners";

function App() {
  return (
    <div className="site-wrapper">
      <div className="pattern-bg">
        <div className="site-container">
          <LandingBlock />
          <div className="site-content">
            <WhatWeDo />
            <WhoWeAre />
            <Partners />
            <Contact />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;

import React from "react";
import "./style.css";

export default function Footer() {
  return (
    <div className="footer-container">
      <h2>CosmicDot•</h2>
      <p>
        CosmicDot SRLs. P.I. IT153 03961005 | REA RM-1581381 | Via Della Nocetta
        109, 00164 Roma | hello {"[<a/t>]"} cosmicdot.com | cosmicdot{" "}
        {"[<a/t>]"} pec.it
      </p>
    </div>
  );
}
